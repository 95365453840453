@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Farro:wght@300;400;500;700&display=swap');

:root
{
    --white: #ffffff;
    --black: #000000;
    --gray: #616161;
    --lightgray: #9f9fa1;
    --transparent: #00000000;
    --transparent_black: #000000b0;
    --transparent_white: #ffffffc0;
    --grayblue: #1e4c76;
    --blue: #075aa7;
    --red: #ad2300;
    --green: #1a793c;
    --smallfont: clamp(0.8rem,1.125vw,2.4rem);
    --basicfont: clamp(1rem,1.5vw,1.5rem);
    --spanfont: clamp(1.3rem,2vw,2rem);
    --bigfont: clamp(1.5rem,4.5vw,4.5rem);
    --mottofont: clamp(3.5rem,8vw,7rem)
}

*, html 
{
    scroll-behavior: smooth !important;
    font-family: 'Farro', sans-serif;
    font-weight: 400;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}
body
{
    width: 100vw; 
    background-color: var(--white);
    margin: 0;
    padding: 0;
    overflow-x: clip;
    overflow-y: scroll;
}
::-webkit-scrollbar 
{
    -webkit-appearance: none;
    display: none;
}
p.last
{
    margin-bottom: 0;
}
