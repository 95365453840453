.arrow_up{
    width: 2vh;
    height: 2vh;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: translateY(25%) rotate(-135deg);
    -webkit-transform: translateY(25%) rotate(-135deg); 
}
#btn.top{
    width: 10vh;
    height: 10vh;
    background-color: var(--white);
    border-radius: 50%;
    border: clamp(2px, 0.3vh, 4px) solid var(--black);
    position: fixed;
    z-index: 1000;
    bottom: -20vh;
    right: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.65;
    transform: scale(0.65);
    transition: bottom 1s, opacity 0.7s, transform 0.7s;
    cursor: pointer;
}
#btn.top:hover{
    opacity: 0.85;
    transform: scale(0.85);
}