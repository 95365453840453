.center
{
    display: flex;
    align-items: center;
    flex-direction: column;
}
#fake_form
{
    display: none;
}
#email_form
{
    width: 100%;
    max-width: 1200px;
}
.form
{
    padding: 5vh 4vw;
    padding-top: 0;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form span
{
    font-size: var(--spanfont);
    font-weight: 700;
    text-transform: uppercase;
}
.form form
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--smallfont);
    line-height: 1.7;
    max-width: 800px;
    width: 100%;
    padding: 2vw;
    margin:  0 2vw;
}
.form label
{
    width: calc(100% - 16px - 0.25vw);
    text-align: start;
    padding: 0.25vw;
    padding-left: 16px;
    font-size: var(--smallfont);
}
.form input
{
    width: calc(100% - 32px);
    text-align: start;
    padding: 8px 16px;
    margin-bottom: 1vw;
    font-size: var(--smallfont);
    font-weight: 300;
}
.form textarea
{
    width: calc(100% - 32px);
    text-align: start;
    padding: 8px 16px;
    font-size: var(--smallfont);
    font-weight: 300;
    resize: none;
    overflow-y: scroll;
}
.form textarea:focus, .form input:focus
{
    outline: none;
}
textarea::-webkit-scrollbar  
{
    display: block;
    width: 4px;
}
 .form textarea::-webkit-scrollbar-thumb
{
    background: var(--blue);
} 
.form input#submit
{
    width: 20%;
    text-align: center;
    padding: 15px 30px;
    border-width: 0;
    border-radius: 3px;
    color: var(--white);
    background-color: var(--blue);
    cursor: pointer;
}
.form button
{
    width: auto;
    text-align: center;
    padding: 15px 30px;
    border-width: 0;
    border-radius: 3px;
    color: var(--white);
    background-color: var(--blue);
    font-size: var(--smallfont);
    cursor: pointer;
    margin: 0 auto;
}
div.error
{
    color: var(--red);
    margin-bottom: 1vw;
    padding-left: 16px;
    font-size: var(--smallfont);
}
.error-border
{
    outline: auto;
    outline-color: var(--red);
}
#success
{
    color: var(--green);
    font-size: var(--smallfont);
    display: none;
}
.contact p b{
    font-weight: normal;
    font-size: var(--smallfont);
}

@media only screen and (max-width: 1250px) 
{
    .form form
    {
        max-width: 600px;
    }
}