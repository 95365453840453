#slider
{
    position: relative;
    width: 100vw;
    height: calc(100vw /16 *5);
    min-height: 400px;
    max-height: 700px;
    background-color: var(--transparent);
}
#slide
{
    position: absolute;
    z-index: -500;
    left: 0%;
    height: calc(100vw /16 *5);
    min-height: 400px;
    max-height: 700px;
    width: 600vw;
    background-color: var(--white);
    transition: left linear 1s;
}
.slide
{
    margin: 0;
    padding: 0;
    width: 100vw;
    height: inherit;
    min-height: 400px;
    max-height: 700px;
    float: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.slide.s1
{
    background-image: url("../../../../public/img/other/chmury.webp");
    background-position: top left;
    background-size: cover;
}
.slide.s2
{
    background-image: url("../../../../public/img/gallery/webp/horyzont_prawy.webp");
    background-position: center center;
    background-size: 101% auto;
}
.slide.s3
{
    background-image: url("../../../../public/img/gallery/webp/pejzaz2021.webp");
    /* background-image: url("../../../../public/img/gallery/toskania_cisza_zwiastujaca_zmiany.webp"); */
    background-position: bottom center;
    background-size: cover;
}

.slider_arrow_right
{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: clamp(20px, 5vh, 60px);
    height: clamp(20px, 5vh, 60px);
}
.slider_arrow_left
{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(50%, -50%);
    width: clamp(20px, 5vh, 60px);
    height: clamp(20px, 5vh, 60px);
}
#slider_arrow
{
    position: absolute;
    width: clamp(20px, 5vh, 60px);
    height: clamp(20px, 5vh, 60px);
    background-color: var(--black);
    opacity: 0.2; 
    transition: opacity 0.3s linear;
    border-radius: 50%;
}
#slider_arrow:hover
{
    opacity: 0.5;
    cursor: pointer;
}
#slider_arrow:hover + .arrow.slider
{
    opacity: 1;
    cursor: pointer;
}
.slider_arrow_right #slider_arrow
{
    transform: translateX(5%);
}
.slider_arrow_left #slider_arrow
{
    transform: translateX(-5%);
}
.arrow
{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1vh;
    height: 1vh;
    padding: 3px;
    border: solid var(--white);
    border-width: 0 3px 3px 0;
    display: block;
    opacity: 0.8;
    transition: opacity 0.3s linear;
    pointer-events: none;
} 
.right
{
    transform-origin: center;
    transform: translate(-50%,-50%) rotate(-45deg);
} 
.left
{
    transform-origin: center;
    transform: translate(-50%,-50%) rotate(135deg);
} 
#bullets
{
    width: auto;
    height: auto;
    position: relative;
    left: 50%;
    top: 92%;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--transparent);
}
.bullet
{
    margin: 0 3vw;
    width: clamp(10px, 2.5vh, 20px);
    height: clamp(10px, 2.5vh, 20px);
    border-radius: 50%;
    border-style: solid;
    border-color: var(--white);
    
    background-color: var(--black);
    opacity: 0.2;
    border-width: 0;

    float: left;
    transition: border-width 1s linear;
    pointer-events: auto;
    cursor: pointer;
}

.selected
{
    background-color: var(--transparent);
    opacity: 1;
    border-width: clamp(3px, 0.62vh, 6px);
}
#slide::after
{
    clear: both;
}

@media only screen and (max-width: 550px) 
{
    /* slider */
    .slide.s2
    {
        background-size: cover;
    }
}