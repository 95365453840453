footer
{
    margin: 0;
    position: relative;
    background:linear-gradient( rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75) ), url("../../../public/img/other/4.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: calc(100% - 4vw);
    padding: 2vw 2vw;
    padding-top: 2vw;
    display: flex;
    flex-direction: column;
}
footer .text
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vw;
}
footer .text span a
{
    text-align: center;
    color: var(--white);
    font-size: var(--basicfont);
    font-weight: 700;
    text-transform: uppercase;
    margin: 1.75vh 0;
    text-decoration: none;
}
footer p
{
  color: var(--white);
  font-size: var(--basicfont);
  text-align: center;
  width: 100%;
  margin-bottom: 2vw;
}
footer .content
{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
}
footer .content .map
{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
iframe
{
    margin: 2vw 2vw;
    width: calc(100% - 4vw);
    height: 40vw;
    max-height: 550px;
    border: clamp(3px, 0.3vh, 6px) solid var(--white);
    pointer-events: none;
}
footer .content .contact
{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}
footer .content .contact p
{
    min-width: 150px;
    font-size: var(--smallfont);
    margin-top: 0;
}
footer .author 
{
    color: var(--white);
    font-size: var(--smallfont);
    text-align: center;
    width: 100%;
}
footer .author h6 
{
    font-size: var(--smallfont);
}
footer .author h6 .creator
{
    font-size: var(--smallfont);
    font-weight: normal;
}

@media only screen and (max-width: 550px) 
{
    footer .content .map iframe
    {
        margin: 2vw;
        width: calc(100% - 4vw);
        height: 80vw;
        border: clamp(3px, 0.3vh, 6px) solid var(--white);
    }
}