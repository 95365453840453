.articles
{
    background-color: var(--white);
    width: 100%;
}
article
{
    width: 100%;
    max-width: 1200px;
    height: 70vh;
    text-align: center;
    margin: 0 auto;
}
article#text
{
    background-color: var(--white);
    width: 100%;
    height: auto;
    padding: 5vh 0;
}
article#text.under_motto
{
    background-color: var(--white);
    padding-top: 0;

}
article#text div#double
{
    color: var(--black);
    width: 100%;
    height: calc(100% - 2em - 7px);
    margin: 0;   
    text-align: justify;
    display: flex;
}
article#text div#double div
{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    align-content: center;
}
article#text div#double div.leftText
{
    padding-right: 2vh;
}
article#text div#double div.rightText
{
    padding-left: 2vh;
}
article#text div#double div h2
{
    font-size: var(--spanfont);
    margin: 0 auto;
    font-weight: 700;
}
article#text div#double div.leftText h2
{
    color: var(--blue)
}
article#text div#double div.rightText h2
{
    color: var(--red);
}
article#text div#double div p
{
    font-size: var(--basicfont);
    margin: 1em 0;
}
article#text div#double div h3
{
    font-size: var(--basicfont);
    margin: 1em 0;
}

article#text div
{
    color: var(--black);
    width: 100%;
    height: calc(100% - 2em - 7px);
    margin: 0;   
    text-align: justify;
}
article#text div span
{
    color: var(--black);
    font-weight: 700;
}
article#text div p
{
    margin: 0;
    color: var(--black);
    line-height: 1.7;
    font-size:  var(--basicfont);
    /* gicior czcionka */
}

article#text div h3
{
    margin: 0;
    color: var(--black);
    line-height: 1.7;
    font-size:  var(--basicfont);
    /* gicior czcionka */
}

article#text.under_motto div p
{
    width: 100%;
    margin: 0;
    color: var(--black);
    line-height: 1.7;
    font-size:  var(--basicfont);
    /* gicior czcionka */
}

article#text.under_motto div h3
{
    width: 100%;
    margin: 0;
    color: var(--black);
    line-height: 1.7;
    font-size:  var(--basicfont);
    /* gicior czcionka */
}

/* center */
article#center
{
    height: auto;
    width: auto;
    max-width: 1200px;
}
.table
{
    max-width: 1200px;
    max-height: 1200px;
    width: 100vw;
    height: 100vw;
    display: flex;
    flex-direction: column;
    background-color: var(--black);
}
.table .row
{
    max-width: inherit;
    width: inherit;
    flex: 1;
    display: flex;
}
.table .row div
{
    position: relative;
    max-width: inherit;
    width: inherit;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.table .row div p
{
    position: relative;
    padding: 0 5vw;
    font-size: var(--bigfont);
    text-align: center;
    font-weight: 500;
    color: var(--white);
    z-index: 100;
    pointer-events: none;
    line-height: 1.5;
    opacity: 1;
}
.table .row div a
{
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 100%;
    width: 100%;
    background-color: var(--transparent);
    border: none;
    border-color: var(--white);
    transition: height ease 0.5s, width ease 0.5s;
    text-decoration: none;
}
a
{
    text-decoration: none;
}
a:visited
{
    text-decoration: none;
}
.table .row div:hover a
{
    height: 85%;
    width: 85%;
    cursor: pointer;
    border: clamp(3px, 0.3vh, 6px) solid var(--white);
}
#o1
{
    /* background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('img/5.webp'); */
    background-color: var(--blue);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}
#o2
{
    /* background: linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url('img/6.webp'); */
    background-image: url("../../../../public/img/other/6.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
}
#o3
{
    /* background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) ), url('img/1.webp'); */
    background-image: url("../../../../public/img/other/1.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
#o4
{
    background-color: var(--red);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
h4 {
    font-weight: normal;
    font-size: var(--basicfont);
    line-height: 1.7;
    margin: 0;
}
article#text.last
{
    padding-bottom: 4vh;
}

@media only screen and (max-width: 1250px) 
{
    article#text
    {
        width: calc(100% - 8vw);
        height: auto;
        padding: 5vh 4vw;
    }
}

@media only screen and (max-width: 750px) 
{
    article#text div#double
    {
        flex-direction: column-reverse;
    }
    article#text div#double div.leftText
    {
        padding-right: 0;
    }
    article#text div#double div.rightText
    {
        padding-left: 0;
    }
    article#text div#double div.rightText p
    {
        margin-bottom: 0;
    }
}