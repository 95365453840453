.motto
{
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    background-color: var(--transparent);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.motto .text
{
    width: 100%;
    max-width: 1200px;
    background-color:var(--white);
    color: var(--black);
    margin: 0;
    padding: 5vh 0;
    font-size: var(--mottofont);
    font-weight: bolder;
    text-transform: uppercase;
    text-align: center;
    mix-blend-mode: screen;
}
.motto #motto_image
{
    position: absolute;
    min-width: 350px;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 0%); 
    width: 100%;
    max-width: 1200px;
    height: 150vh;
    object-fit: contain;
    background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) ), url("../../../public/img/other/4.webp");
    /* background-image:url("../../../../public/img/4.webp") ; */
    background-repeat: repeat-y;
    background-position: center;
    background-size: cover;
    z-index: -1000;
}

