#side_menu
{
    position: absolute;
    width: clamp(220px, 21vw, 420px);
    height: auto;
    top: 12vh;
    left: 0;
    transform: translate(-100%,0);
    display: none;
    flex-direction: column;
    padding: 5vw 7vw;
    padding-top: 0;
    background: var(--blue);
    z-index: 1010;
    transition: transform 0.4s linear;
    text-transform: uppercase;
}
#side_menu a
{
    position: relative;
    top: 0;
    left: 0;
    margin: clamp(10px,3vw,20px) 0;
    font-size: var(--spanfont);
    color: var(--white);
    text-decoration: none;
    text-align: left;
    z-index: 1020;
    cursor: pointer;
    transition: all 0.4s linear;
}
#side_menu a.selected
{
    font-weight: 700;
}

/*  header  */
header
{
    width: 100vw;
    height: 12vh;
    color: var(--white);
    background-color: var(--blue);
}
nav
{
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 7vw;
    height: inherit;
    max-height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
nav img
{
    width: auto;
    height: 60%;
    padding: 0 1vw;
    float: left;
}
section
{
    height: auto;
}
#bars
{
    width: 100px;
    display: none;
}
#menu
{
    position: relative;
    width: 6vh;
    height: 6vh;
    padding: 0;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    transition: all 1s;
}
.bar1, .bar2, .bar3
{
    position: absolute;
    width: inherit;
    height: clamp(3px,0.3vh,4px);
    background-color: var(--white);
    transition: 0.5s;
}
.bar1
{
    top: 10%;
    left: 0;
}
.change .bar1
{
    top: 50%;
    -webkit-transform: translate(0, -50%) rotate(-45deg);
    transform: translate(0, -50%) rotate(-45deg);
}
.bar2
{
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.change .bar2
{
    opacity: 0;
}
.bar3{
    bottom: 10%;
    left: 0;
}
.change .bar3
{
    top: 50%;
    -webkit-transform: translate(0, -50%) rotate(45deg);
    transform: translate(0, -50%) rotate(45deg);
}
#logo
{
    display: none;
    width: 100px;
    height: 80%;
}
#logo .logo_img
{
    /* background-image: url("img/logo.png"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 100%;
    width: 100%;
}
#options
{
    display: block;
}
#large_menu
{
    position: relative;
    height: 100%;
    min-width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--white);
    transition: transform 0.4s linear;
    text-transform: uppercase;
}
#large_menu a
{
    position: relative;
    top: 0;
    left: 0;
    margin: clamp(10px,3vw,20px) 0;
    font-size: var(--basicfont);
    padding: 0 2vw;
    color: var(--white);
    text-decoration: none;
    text-align: center;
    z-index: 1020;
    cursor: pointer;
    transition: all 0.4s linear;
}
#large_menu a.selected
{
    font-weight: 700;
}

#languages 
{
    height: auto;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#languages div
{
    display: inline-flex;
    white-space: nowrap;
    margin: 1vh 0;
    float: right;
    cursor: pointer;
}
#languages p
{
    color: var(--white);
    margin: 0 0 0 auto;
    padding: 0 0.75vw 0 0;
    float: left;
}
#languages img
{
    margin: auto 0;
    padding: 0;
    float: left;
}
header::after
{
    clear: both;
}

@media only screen and (max-width: 1200px) 
{
    #side_menu
    {
        display: flex;
    }
    #bars
    {
        display: block;
    }
    #menu
    {
        display: flex;
    }
    #options
    {
        display: none;
    }
    #large_menu
    {
        display: none;
    }
}