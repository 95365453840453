#lekcje
{
    width: 100%;
    background-color: var(--white);
}
.lekcje
{
    background-color: var(--white);
    padding: 2vw 4vw;
    padding-top: 0;
    width: calc(100% - 8vw);
    height: auto;
    padding-bottom: calc(2vh + 5vw);
    margin: 0 auto;
}
.oferta
{
    text-align: start;
    display: flex;
    height: auto;
    width: 100%;
    max-width: 1200px;
    margin: 0;
}
.oferta .content
{
    width: 60%;
}
.oferta .content .oferty
{
    padding: 0;
    padding-right: 2vw;
    background-color: var(--white);
}
.oferta span
{
    font-size: var(--spanfont);
    color: var(--black);
    font-weight: 700;
    text-transform: uppercase;
}
.oferta p
{
    margin: 0.25em 0;
    margin-bottom: 2em;
    color: var(--black);
    line-height: 1.7;
    font-size: var(--basicfont);
    text-align: justify;
}
.oferta .about_me
{
    width: calc(40% - 4vw);
    flex: 2;
    background-color: var(--white);
    padding: 0;
    padding-left: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.oferta .about_me .image
{
    width: 100%;
    aspect-ratio: 9 / 12;
    background-image: url("../../../../public/img/artist/zdj2.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 5vh;
}
.oferta .about_me p
{
    text-align: justify;
}
.oferta p span.toLeft
{
    display: flex;
    margin: 0;
    line-height: 1.7;
}
span.toLeft, 
p.toLeft
{
    margin: 0.25em 0;
    margin-bottom: 2em;
    color: var(--black);
    line-height: 1.7;
    font-size: var(--basicfont);
    font-weight: 400;
    text-transform: none;
    text-align: start;
}
span.red
{
    color: var(--red);
}
.about_me.desktop
{
    display: block;
}
.about_me.mobile
{
    display: none;
}
@media only screen and (max-width: 750px) 
{
    .oferta
    {
        flex-direction: column;
    }
    .oferta .content
    {
        width: 100%;
    }
    .oferta .content .oferty
    {
        padding: 0;
    }
    .oferta .about_me
    {
        padding: 0;
        width: calc(100% - 4vw);
    }
    .oferta .about_me .image
    {
        width: 60%;
    }
    .about_me.desktop
    {
        display: none;
    }
    .about_me.mobile
    {
        display: block;
    }
    .about_me.mobile .image
    {
        margin-bottom: 2em;
    }
    .about_me.mobile p
    {
        margin: 0.25em 0;
        margin-bottom: 2em;
    }
}