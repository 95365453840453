.center
{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--white);
    padding-bottom: 5vh;
}

.card
{
    width: 80vw;
    max-width: 800px;
    margin-bottom: 4vw;
    border: solid 2px var(--blue);
    border-radius: 2vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--white);
}
p.name{
    margin-top: 12px;
    margin-bottom: 0;
    font-size: var(--basicfont);
    font-weight: bold;
    text-align: center;
}
.stars{
    margin: 4px 0;
}
p.text{
    margin-top: 0;
    margin-bottom: 12px;
    font-size: var(--smallfont);
    padding: 0 18px;
}

.checked {
  color: orange;
}

@media only screen and (max-width: 1250px) 
{

}